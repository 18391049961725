<template>
  <div>
    <title-bar
      :items="title_page"
    >
    </title-bar>
    <div class="box">
      <Form 
        v-model="row"
        @oncancel="$router.go(-1)"
        @onsuccess="$router.go(-1)" />
    </div>
  </div>
</template>

<script>
// 
import Form from "../components/forms/FiltroForm";
export default {
  components: {
    Form,
  },
  data(){
    return {
      row: {}
    }
  },
  computed:{
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    title_page(){
      return this.$store.getters[`form_1617124496702/title_bar`]({
        title: `Opciones de ${this.$route.meta.title}`
      })
    }
  },
  created(){
    this.row = {
      id: this.dashboard.id,
      filtros: this.dashboard.attrs.filtros || []
    }
  },
};
</script>