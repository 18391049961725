<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-4">
        <p>Arrastre los campos a la derecha:</p>
        <TreeCampos key-rows="filtros_dinamicos" />
      </div>
      <div class="column is-4">
        <TreeFiltros v-model="form.filtros" />
      </div>
    </div>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
      <b-button
        type="is-secondary"
        icon-left="times"
        :loading="loading"
        class="mt-3"
        @click="$emit('oncancel')"
        >Cancelar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
import TreeCampos from "../campos/TreeCampos.vue"
import TreeFiltros from "../filtros/TreeFiltros.vue"
export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  components: {
    TreeCampos,
    TreeFiltros,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    dashboard(){
      return this.$store.getters[`form_1617124496702/row`]
    },
    form:{
      get(){
        return this.value || {}
      },
      set(e){
        this.$emit("input", e)
      }
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`form_1617124496702/save`, this.form)
      if(row){
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de guardar los filtros globales`
        );
        this.$emit("onsuccess")
      }
      this.loading = false;
    },
  },
};
</script>