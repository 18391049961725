<template>
  <div class="drag-group p-2">
    <div>
      <input-base
        placeholder="Buscar campos"
        size="is-small"
        v-model="search" />
    </div>
    <div class="mt-2">
      <ul class="lista">
        <li class="my-1" v-for="(item,i) in campos" :key="i">
          <b-collapse animation="slide">
            <template #trigger="{open}">
              <span class="has-text-weight-semibold is-clickable">
                <b-icon 
                  :icon="open ? `chevron-down` : `chevron-right`" 
                  size="is-small" />{{item.name}}
              </span>
            </template>
            <draggable 
              tag="ul" 
              class="ml-4" 
              :list="item.rows" 
              :group="{ name: `people`, pull: 'clone', put: true }" 
              ghost-class="drag-ghost" 
              :sort="false" >
              <li v-for="(row,k) in item.rows" :key="`${i}${k}`" v-show="isShow(row)">
                {{row.label}}
              </li>
            </draggable>
          </b-collapse>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { nameStoreForm } from "@/utils/helpers";
const name_store = nameStoreForm( require.context("../../", false, /\.name$/i) );
// 
export default {
  props:{
    keyRows:{
      type: String,
      default: () => `campos_dinamicos`
    }
  },
  data(){
    return {
      search: ``,
    }
  },
  computed:{
    rows(){
      return this.$store.getters[`${name_store}/${this.keyRows}`]
    },
    campos(){
      return this.rows.filter(e => this.getRows(e).length)
    },
    getRows(){
      return e => e.rows.filter(r => this.isShow(r))
    } 
  },
  methods: {
    isShow(r){
      return (r.label || r.newLabel).toLowerCase().indexOf(this.search.toLowerCase()) > -1
    }
  }
}
</script>
<style scoped>
.lista{
  height: 400px;
  overflow-y: scroll;
}
</style>