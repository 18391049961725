<template>
  <div>
    <p class="has-text-weight-bold">Filtros</p>
    <draggable 
      tag="ul" 
      class="drag-group lista" 
      v-model="list"
      group="people" 
      ghost-class="drag-ghost" >
      <li 
        class="drag-item" 
        v-for="element in list" 
        :key="element.key">
          {{element.newLabel}} 
          <span @click.prevent="handleClick(element)" class="is-clickable has-text-info">
            <b-icon icon="info-circle" />
          </span>
      </li>
    </draggable>
    <ModalForm 
      v-model="form"
      :show="showModal"
      @modal="showModal = !showModal"
      @cancel="handleClose" />
  </div>
</template>

<script>
import ModalForm from "./Modal.vue"
export default {
  props:{
    value: {
      type: Array,
      default: () => []
    }
  },
  components: {
    ModalForm
  },
  data(){
    return {
      showModal: false,
      form : {}
    }
  },
  computed:{
    list:{
      get(){
        return this.value || []
      },
      set(e){
        e = e.filter((r,p,o) => o.indexOf(r) === p)
        if(e.length){
          const index = e.findIndex(r => !r.newLabel)
          if(index >= 0) {
            e[index].newLabel = e[index].label
          }
        }
        this.$emit("input", [...e])
      }
    }
  },
  methods:{
    handleClick(e){
      this.form = {
        key: e.key,
        label: e.label,
        etiqueta: e.newLabel,
      }
      this.showModal = true
    },
    handleClose(){
      if(this.form.etiqueta){
        const index = this.list.findIndex(r => r.key === this.form.key)
        if(index >= 0){
          this.list[index].newLabel = this.form.etiqueta
        }
      }
    }
  }
}
</script>
<style scoped>
.lista{
  max-height: 450px;
  overflow-y: scroll;
}
</style>