<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <p class="mb-2">Campo de origen: <strong>{{form.label}}</strong></p>
    <div class="columns is-flex-wrap-wrap">
      <div class="column is-12">
        <input-base
          rules="required"
          label="Etiqueta para mostrar"
          maxlength="150"
          v-model="form.etiqueta"
          focus
        />
      </div>
    </div>
    <b-button
      type="is-secondary"
      icon-left="times"
      class="mt-3"
      @click="$emit('oncancel')"
      >Cerrar</b-button
    >
  </form-observer>
</template>
<script>
export default {
  props:{
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed:{
    form:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input",e)
      }
    },
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.$emit('onsuccess')
    }
  }
}
</script>